import React from 'react';
import PropTypes from 'prop-types';
import './styles/VideoComponentTutorial.css';

const VideoComponent = ({ videoSrc }) => {
  return (
    <div className="video-container">
      <video
        className="background-video"
        src={videoSrc}
        controls
        playsInline
      />
    </div>
  );
};

VideoComponent.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VideoComponent;
