import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import FloatingMenu from './FloatingMenu';
import './styles/GameModelsPage.css';

import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setAccessToken,
} from '../api/authUtils';
// Nota: la libreria corretta è jwt-decode (senza graffe) se installata come pacchetto esterno
import { jwtDecode } from 'jwt-decode'; 
import apiClient from '../api/apiClient';

/** Array dei modelli di giochi interattivi **/
const interactiveAgents = [
  {
    name: 'Quiz',
    description: 'Genera quiz per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Completa Frase',
    description: 'Esercizi per completare frasi con opzioni multiple.',
    image: '/people/fillthebox.png',
    agentKey: 'fillthebox',
  },
  {
    name: 'Raggruppa',
    description: 'Crea liste di parole suddivise in gruppi tematici.',
    image: '/people/selectgroup.png',
    agentKey: 'selectgroup',
  },
  {
    name: 'Abbina Coppie',
    description: 'Crea esercizi di abbinamento (match pairs).',
    image: '/people/matchpairs.png',
    agentKey: 'matchpairs',
  },
  {
    name: 'Riordina',
    description: 'Crea esercizi in cui si devono riordinare le frasi.',
    image: '/people/rearrange.png',
    agentKey: 'rearrange',
  },
  {
    name: 'Cruciverba',
    description: 'Genera cruciverba personalizzati a partire da parole chiave.',
    image: '/people/crossword.png',
    agentKey: 'crossword',
  },
];

const GameModelsPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  const navigate = useNavigate();

  // Funzione per controllare l'autenticazione (simile a quella di Resources)
  const checkAuthStatus = async () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken) {
      try {
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp && decoded.exp > currentTime) {
          setIsLoggedIn(true);
        } else if (refreshToken) {
          // Tenta il refresh
          try {
            const response = await apiClient.post('/api/accounts/token/refresh/', {
              refresh: refreshToken,
            });
            if (response.status === 200) {
              const data = response.data;
              setAccessToken(data.access);
              setIsLoggedIn(true);
            } else {
              removeTokens();
              setIsLoggedIn(false);
            }
          } catch (error) {
            console.error('Errore nel refresh del token:', error);
            removeTokens();
            setIsLoggedIn(false);
          }
        } else {
          removeTokens();
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Errore decodifica token:', error);
        removeTokens();
        setIsLoggedIn(false);
      }
    } else if (refreshToken) {
      // Tenta refresh se esiste solo refreshToken
      try {
        const response = await apiClient.post('/api/accounts/token/refresh/', {
          refresh: refreshToken,
        });
        if (response.status === 200) {
          const data = response.data;
          setAccessToken(data.access);
          setIsLoggedIn(true);
        } else {
          removeTokens();
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Errore nel refresh del token:', error);
        removeTokens();
        setIsLoggedIn(false);
      }
    } else {
      // Nessun token
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Se vuoi far apparire il FloatingMenu quando scrollY > 100
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Funzione per gestire il click sulla card
  const handleCreateGame = (agentKey) => {
    if (isLoggedIn) {
      navigate('/chat', { state: { agentKey } });
    } else {
      // Se non loggato, reindirizza al login
      navigate('/login');
    }
  };

  return (
    <div className="models-page">
      {/* HEADER */}
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {/* MOBILE MENU (mostrato se mobileMenuOpen = true) */}
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      {/* FLOATING MENU (mostrato se showFloatingMenu = true) */}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn} 
        />
      )}

      {/* CONTENUTO PRINCIPALE */}
      <main className="models-main">
        {/* Sezione introduttiva */}
        <section className="intro">
          <h1>Modelli di Giochi Interattivi</h1>
          <p>
            Utilizziamo l'intelligenza artificiale per generare giochi interattivi,
            senza che tu debba progettare ogni gioco a mano. Scegli il modello che
            preferisci e inizia subito!
          </p>
        </section>

        {/* Griglia dei modelli */}
        <section className="models-grid">
          {interactiveAgents.map((agent) => (
            <button
              key={agent.agentKey}
              className="model-card"
              onClick={() => handleCreateGame(agent.agentKey)}
            >
              <img src={agent.image} alt={agent.name} className="model-image" />
              <div className="model-content">
                <h3>{agent.name}</h3>
                <p className="model-description">{agent.description}</p>
              </div>
            </button>
          ))}
        </section>

        {/* Sezione tutorial "Detto, fatto!" con 3 step (sotto la griglia) */}
        <section className="tutorial-steps">
          <h2>Detto, fatto!</h2>
          <p className="subtitle">
            Crea un gioco interattivo personalizzato con poche parole e in pochi clic.
          </p>

          <div className="steps-container">
            <div className="step-box">
              <img
                src="/modelspage/GameStep1.png"
                alt="Seleziona il modello"
                className="step-image"
              />
              <h3 className="step-number">1</h3>
              <p className="step-text">
                Seleziona il modello di gioco da creare nella sezione chat di Minerva
              </p>
            </div>

            <div className="step-box">
              <img
                src="/modelspage/GameStep2.png"
                alt="Chiedi all'AI di crearlo"
                className="step-image"
              />
              <h3 className="step-number">2</h3>
              <p className="step-text">
                Chiedi all’intelligenza artificiale di crearlo con gli argomenti
                che preferisci.
              </p>
            </div>

            <div className="step-box">
              <img
                src="/modelspage/GameStep3.png"
                alt="Gioco pronto"
                className="step-image"
              />
              <h3 className="step-number">3</h3>
              <p className="step-text">
                In pochi secondi avrai il gioco da salvare o giocare sulla piattaforma.
              </p>
            </div>
          </div>
        </section>
      </main>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default GameModelsPage;
