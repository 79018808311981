import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../Header';
import MobileMenu from '../MobileMenu';
import Footer from '../Footer';
import FloatingMenu from '../FloatingMenu';
import VideoComponent from './VideoComponentTutorial';
import apiClient from '../../api/apiClient';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../../api/authUtils';
import { jwtDecode } from 'jwt-decode'; // Corretto import di jwtDecode
import './styles/Tutorial.css';

const TutorialPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  // Check token validity on mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            // Access token ancora valido
            setIsLoggedIn(true);
          } else if (refreshToken) {
            // Provo a rinfrescare il token
            try {
              const response = await apiClient.post('/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        // Se ho un refresh token ma non ho un access token, provo a rinfrescare
        try {
          const response = await apiClient.post('/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        // Non ho token
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  // Mostra FloatingMenu dopo uno scroll di 100px
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Varianti per le animazioni con framer-motion
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Tutorial di esempio (4 tutorial distinti)
  const tutorials = [
    {
      id: 1,
      title: 'Come Iscriversi e Iniziare Subito',
      description:
        'Una guida passo-passo per la tua prima iscrizione e per iniziare in pochi minuti.',
      videoSrc: '/video/subscription.mp4',
    },
    {
      id: 2,
      title: 'Creare la Tua Prima Attività Didattica',
      description:
        'Impara ad utilizzare i template e le risorse didattiche di Minerva AI.',
      videoSrc: '/video/Minerva_Tutorial_Parte_1.mp4',
    },
    {
      id: 3,
      title: 'Trasforma e Adatta le Tue Risorse Didattiche con Minerva',
      description:
        'Scopri come digitalizzare testi o attività dai libri, semplificarli, personalizzarli per diversi livelli di apprendimento e generare quiz automatici con Minerva AI.',
      videoSrc: '/video/Minerva_Tutorial_Creazione_Quiz.mp4',
    },
    {
      id: 4,
      title: 'Crea Quiz e Giochi Interattivi con Minerva',
      description:
        "Impara a generare quiz personalizzati e attività ludiche interattive con Minerva AI, trasformando qualsiasi argomento in un'esperienza coinvolgente per gli studenti.",
      videoSrc: '/video/Minerva_Tutorial_Creazione_Quiz.mp4',
    },
  ];

  return (
    <div className="tutorial-page">
      {/* HEADER */}
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {/* MENU MOBILE (se aperto) */}
      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      {/* FLOATING MENU (se abilitato dallo scroll) */}
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
          }}
        />
      )}

      <div className="container_tutorial">
        <main>
          <motion.section
            className="tutorial-section"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <h1>Tutorial</h1>
            
            {/* -----------------------------
                BLOCCO AGGIUNTO: INVITO LIVE
               ----------------------------- */}
            <div className="live-tutorial-invite">
              <p>Partecipa al tutorial live il giorno 22 Febbraio alle 15:00</p>
              <a
                className="live-button"
                href="https://forms.gle/Yc7AKe5rfdp96mdLA"
                target="_blank"
                rel="noopener noreferrer"
              >
                Iscriviti
              </a>
            </div>
            {/* FINE BLOCCO AGGIUNTO */}

            <p>
              Benvenuto nella sezione tutorial di Minerva AI.
              Qui troverai guide utili e pratiche per iniziare al meglio.
            </p>

            {tutorials.map((tutorial, index) => (
              <div
                key={tutorial.id}
                className={`tutorial-row ${index % 2 !== 0 ? 'reverse' : ''}`}
              >
                {/* TESTO  */}
                <div className="tutorial-row-text">
                  <h2>{tutorial.title}</h2>
                  <p>{tutorial.description}</p>
                </div>

                {/* VIDEO */}
                <div className="tutorial-row-video">
                  <VideoComponent videoSrc={tutorial.videoSrc} />
                </div>
              </div>
            ))}
          </motion.section>
        </main>
      </div>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default TutorialPage;
