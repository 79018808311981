import React, { useState, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery
} from '@mui/material';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FolderIcon from '@mui/icons-material/Folder';

import useGetDocuments from '../../api/documents/useGetDocuments';
import { keyframes } from '@emotion/react';

// Animazione "pulse" stile AgentStart
const pulseBorder = keyframes`
  0% {
    box-shadow: 0 0 0 4px rgba(223,70,52,0.5);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(223,70,52,0.2);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(223,70,52,0.5);
  }
`;

const MessageInput = ({
  newMessage,
  setNewMessage,
  handleSendMessage,
  files,
  setFiles,
  inputRef,
  // Nuova prop: se true, mostriamo il testo animato e bordo pulsante
  tutorialFocusOnInput = false,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { documents, loading } = useGetDocuments();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDocumentList, setShowDocumentList] = useState(false);

  // Apertura menù allegati
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Selezione da “Profilo” (documenti salvati)
  const handleSelectFromProfile = () => {
    setShowDocumentList(true);
    handleClose();
  };

  // Selezione file da input
  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  // Drag & Drop
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Rimuovi file
  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  // Icone in base al file
  const getFileIcon = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon color="error" />;
      case 'doc':
      case 'docx':
        return <InsertDriveFileIcon style={{ color: '#3F51B5' }} />;
      case 'txt':
        return <InsertDriveFileIcon style={{ color: '#4CAF50' }} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon style={{ color: '#FF9800' }} />;
      default:
        return <AttachFileIcon />;
    }
  };

  // Selezione documento dal profilo
  const handleSelectDocument = async (doc) => {
    try {
      const response = await fetch(doc.file_url);
      if (!response.ok) {
        throw new Error('Errore nel fetch del file dal profilo');
      }
      const blob = await response.blob();

      // Estensione
      const extensionMatch = doc.document_name.match(/\.[0-9a-z]+$/i);
      const extension = extensionMatch ? extensionMatch[0] : '';
      let fileName = doc.document_name;
      if (!extension) {
        const mimeToExtension = {
          'application/pdf': '.pdf',
          'application/msword': '.doc',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
          'text/plain': '.txt',
        };
        const inferredExtension = mimeToExtension[blob.type] || '';
        fileName = `${doc.document_name}${inferredExtension}`;
      }

      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });
      setFiles((prevFiles) => [...prevFiles, file]);

      setShowDocumentList(false);
    } catch (error) {
      console.error('Errore nel caricamento file dal profilo:', error);
      alert('Errore nel caricamento del file dal profilo. Riprova più tardi.');
    }
  };

  // Selezione da camera (mobile)
  const handleCameraSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSendMessage}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        backgroundColor: '#FFFFFF',
        borderRadius: '1.875rem',
        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
        width: isMobile ? '100%' : 'calc(100% - 8.75rem)',
        maxWidth: '100%',
        margin: '0 auto',
        marginTop: '0.65rem',
        position: 'relative',
        zIndex: 1,
        // Per abilitare drop
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {/* Se tutorialFocusOnInput => testo animato sopra l’input */}
      {tutorialFocusOnInput && (
        <Typography
          sx={{
            position: 'absolute',
            top: '-2.5rem',
            left: 0,
            right: 0,
            textAlign: 'center',
            color: '#DF4634',
            fontWeight: 'bold',
            animation: 'bounceText 1.5s infinite',
            marginBottom: '0.5rem',
            '@keyframes bounceText': {
              '0%, 100%': { transform: 'translateY(0)' },
              '50%': { transform: 'translateY(-5px)' },
            },
          }}
        >
          Scrivi la classe e l’argomento dell’attività, poi premi il bottone Invia!
        </Typography>
      )}

      {/* Lista file caricati */}
      {files.length > 0 && (
        <Box
          sx={{
            width: '100%',
            padding: '0.625rem',
            overflowY: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
          }}
        >
          {files.map((file, index) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
              return (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    width: '100px',
                    height: '100px',
                    borderRadius: '0.5rem',
                    overflow: 'hidden',
                    border: '1px solid #ccc',
                  }}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleRemoveFile(file.name)}
                    sx={{
                      position: 'absolute',
                      top: '2px',
                      right: '2px',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                      },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              );
            } else {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                    justifyContent: 'space-between',
                    backgroundColor: '#f5f5f5',
                    padding: '0.5rem 1rem',
                    borderRadius: '0.5rem',
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getFileIcon(file)}
                    <Typography
                      variant="body2"
                      sx={{ marginLeft: '0.5rem', wordBreak: 'break-all' }}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                  <Button
                    size="small"
                    color="error"
                    onClick={() => handleRemoveFile(file.name)}
                  >
                    Rimuovi
                  </Button>
                </Box>
              );
            }
          })}
        </Box>
      )}

      {/* Documenti salvati (dal Profilo) */}
      {showDocumentList && (
        <Box
          sx={{
            width: '100%',
            padding: '0.625rem',
            backgroundColor: '#f5f5f5',
            borderRadius: '1rem',
            position: 'relative',
            marginBottom: '1rem',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={() => setShowDocumentList(false)}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            sx={{ marginBottom: '1rem', textAlign: 'center' }}
          >
            Documenti Salvati
          </Typography>

          {loading ? (
            <Typography>Caricamento documenti...</Typography>
          ) : (
            <List>
              {documents.map((doc) => (
                <ListItem
                  key={doc.id}
                  button
                  onClick={() => handleSelectDocument(doc)}
                >
                  <ListItemIcon>{getFileIcon({ name: doc.document_name })}</ListItemIcon>
                  <ListItemText primary={doc.document_name} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}

      {/* Box con input e bottone invio */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          // Se tutorialFocusOnInput => animazione stile "pulse" attorno
          ...(tutorialFocusOnInput
            ? {
                animation: `${pulseBorder} 1.5s infinite`,
                borderRadius: '2rem',
              }
            : {}),
        }}
      >
        <IconButton onClick={handleClick}>
          <AttachFileIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              width: isMobile ? '50%' : '300px',
              borderRadius: '0.5rem',
              backgroundColor: '#FFFFFF',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            },
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: isMobile ? 'center' : 'left',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: isMobile ? 'center' : 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              document.getElementById('file-input').click();
              handleClose();
            }}
            sx={{ padding: '1rem' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ fontSize: '1rem', color: '#000' }}>
                Carica File
              </Typography>
              <UploadFileIcon sx={{ fontSize: '1.5rem', color: '#000' }} />
            </Box>
          </MenuItem>

          {isMobile && (
            <MenuItem
              onClick={() => document.getElementById('camera-input').click()}
              sx={{ padding: '1rem' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography sx={{ fontSize: '1rem', color: '#000' }}>
                  Scatta una Foto
                </Typography>
                <CameraAltIcon sx={{ fontSize: '1.5rem', color: '#000' }} />
              </Box>
            </MenuItem>
          )}

          <MenuItem onClick={handleSelectFromProfile} sx={{ padding: '1rem' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ fontSize: '1rem', color: '#000' }}>
                Seleziona dal Profilo
              </Typography>
              <FolderIcon sx={{ fontSize: '1.5rem', color: '#000' }} />
            </Box>
          </MenuItem>
        </Menu>

        {/* Input per caricare file */}
        <input
          accept=".txt,.pdf,.docx,.doc,.jpg,.jpeg,.png,.gif"
          style={{ display: 'none' }}
          id="file-input"
          multiple
          type="file"
          onChange={handleFileSelect}
        />

        {/* Nuovo input per camera su mobile */}
        {isMobile && (
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="camera-input"
            capture="environment"
            type="file"
            onChange={handleCameraSelect}
          />
        )}

        <TextField
          inputRef={inputRef} // Attach the ref to the input
          fullWidth
          variant="outlined"
          multiline
          minRows={1}
          maxRows={5}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Scrivi un messaggio a MinervaAI..."
          autoComplete="off"
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '1.875rem',
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
              '& textarea': {
                maxHeight: '150px',
                overflow: 'auto',
              },
            },
          }}
        />

        <Button
          type="submit"
          sx={{
            marginLeft: '0.3rem',
            marginRight: '0.625rem',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            width: '1.875rem',
            height: '1.875rem',
            minWidth: '1.875rem',
          }}
        >
          <img
            src="/chat_icon/send_icon.png"
            alt="Invia"
            style={{ width: '1.25rem', height: '1.25rem' }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default MessageInput;
