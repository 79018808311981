import React from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SidebarContent = ({
  chats,
  selectedChat,
  selectChat,
  handleNewChat,
  handleDeleteChat,
  handleLogout,
  navigate,
  selectedOption,
  onClose,
  onMyGamesClick,
}) => {
  // Agenti di tipo "gioco"
  const gameAgents = ['quiz', 'fillthebox', 'selectgroup', 'matchpairs', 'rearrange', 'crossword'];

  // Determiniamo se esiste almeno una chat con quegli agent e title != null
  const hasAttivita = chats.some((chat) => chat.agent === 'attività' && chat.title !== null);
  const hasQuiz = chats.some((chat) => chat.agent === 'quiz' && chat.title !== null);
  const hasLaboratori = chats.some((chat) => chat.agent === 'laboratori' && chat.title !== null);

  // Se tutte e tre sono vere => missioni completate
  const allMissionsCompleted = hasAttivita && hasQuiz && hasLaboratori;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      {/* Contenitore per il logo e il titolo */}
      <Box sx={{ padding: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 400, marginRight: '1rem' }}>
            Minerva AI
          </Typography>
          <Box
            sx={{
              width: '2.5rem',
              height: '2.5rem',
              backgroundColor: '#DF4634',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            <Box
              component="img"
              src="/logo/logobianco.svg"
              alt="Logo"
              sx={{ width: '2.8rem', height: '2.8rem' }}
            />
          </Box>
        </Box>

        {/* Bottone "Nuova Chat" */}
        <Box sx={{ marginTop: '1rem' }}>
          <Button
            variant="contained"
            onClick={() => {
              handleNewChat(selectedOption);
              onClose();
            }}
            sx={{
              backgroundColor: '#DF4634',
              borderRadius: '1.875rem',
              width: '100%',
              textTransform: 'none',
              fontSize: '0.7rem',
              fontWeight: 400,
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            + Nuova chat
          </Button>
        </Box>

        {/* Sezione MISSIONI => visibile solo se !allMissionsCompleted */}
        {!allMissionsCompleted && (
          <Box sx={{ marginTop: '1.5rem', width: '100%' }}>
            <Typography
              sx={{
                color: '#6B7280',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: 'normal',
                textAlign: 'left',
                marginBottom: '0.3rem',
              }}
            >
              Missioni
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    sx={{
                      color: '#DF4634',
                      '&.Mui-checked': {
                        color: '#DF4634',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    onClick={() => {
                      handleNewChat('attività');
                      onClose();
                    }}
                    sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
                  >
                    Crea la tua prima attività didattica
                  </Typography>
                }
                checked={hasAttivita}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    sx={{
                      color: '#DF4634',
                      '&.Mui-checked': {
                        color: '#DF4634',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    onClick={() => {
                      handleNewChat('quiz');
                      onClose();
                    }}
                    sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
                  >
                    Crea il tuo primo quiz interattivo
                  </Typography>
                }
                checked={hasQuiz}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    sx={{
                      color: '#DF4634',
                      '&.Mui-checked': {
                        color: '#DF4634',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    onClick={() => {
                      handleNewChat('laboratori');
                      onClose();
                    }}
                    sx={{ fontSize: '0.8rem', cursor: 'pointer' }}
                  >
                    Crea un laboratorio didattico
                  </Typography>
                }
                checked={hasLaboratori}
              />
            </FormGroup>
          </Box>
        )}
      </Box>

      {/* Lista delle conversazioni */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '0 1rem',
        }}
      >
        <Typography
          sx={{
            color: '#6B7280',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 'normal',
            textAlign: 'left',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          Conversazioni
        </Typography>

        <List>
          {chats?.map((chat) => {
            const isGameAgent = gameAgents.includes(chat.agent);
            const iconSrc = isGameAgent
              ? selectedChat?.id === chat.id
                ? '/chat_icon/gamehistoryred.png'
                : '/chat_icon/gamehistory.png'
              : selectedChat?.id === chat.id
                ? '/chat_icon/chatred.png'
                : '/chat_icon/chat.png';

            return (
              <ListItem
                key={chat.id}
                button
                onClick={() => {
                  selectChat(chat);
                  onClose();
                }}
                sx={{
                  padding: '0.625rem 0rem',
                  borderRadius: '1.875rem',
                  marginBottom: '0.625rem',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'rgba(223, 70, 52, 0.1)',
                  },
                  '&:hover .delete-icon': {
                    visibility: 'visible',
                  },
                }}
              >
                <Box
                  component="img"
                  src={iconSrc}
                  alt="Chat Icon"
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    marginRight: '0.5rem',
                  }}
                />
                <ListItemText
                  primary={chat.title || `Nuova Chat`}
                  sx={{
                    color:
                      selectedChat?.id === chat.id ? '#DF4634' : 'inherit',
                    fontWeight:
                      selectedChat?.id === chat.id ? 'bold' : 'normal',
                    fontSize: '0.875rem',
                    maxWidth: '20ch',
                    wordBreak: 'break-word',
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteChat(chat.id);
                  }}
                  className="delete-icon"
                  sx={{
                    position: 'absolute',
                    right: '0.625rem',
                    visibility: 'hidden',
                  }}
                >
                  <img
                    src="/chat_icon/remove.png"
                    alt="Remove Chat"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Bottoni in fondo al sidebar */}
      <Box
        sx={{
          padding: '1rem',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        {/* BOTTONE GIOCHI */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            onMyGamesClick(true);
            onClose();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/games.png"
              alt="Games Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Giochi
          </Box>
        </Button>

        {/* BOTTONE HOME */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/');
            onClose();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/home.png"
              alt="Home Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Home
          </Box>
        </Button>

        {/* BOTTONE PROFILO */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/profile');
            onClose();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/profile.png"
              alt="Profile Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Profilo
          </Box>
        </Button>

        {/* BOTTONE RISORSE */}
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/resources');
            onClose();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/resources.png"
              alt="Resources Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Risorse
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarContent;
