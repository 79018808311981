import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Dialog,
  Slide,
  Button,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

/* ==============================
   AGENTI PRINCIPALI
   ============================== */
const primaryAgents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.png',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.png',
    agentKey: 'attività',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Idea e sviluppa laboratori e progetti stimolanti.',
    image: '/people/laboratorio.png',
    agentKey: 'laboratori',
  },
  {
    name: 'Scrittura',
    description: 'Assistenza nella redazione di UDA, verbali, programmazioni, etc.',
    image: '/people/scrittura.png',
    agentKey: 'scrittura',
  },
  {
    name: 'Minerva',
    description: 'Un’AI con profonda conoscenza del sistema scolastico italiano.',
    image: '/people/minerva.png',
    agentKey: 'minerva',
  },
];

/* ==============================
   AGENTI SECONDARI
   ============================== */
const secondaryAgents = [
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
  {
    name: 'ImmaginAI',
    description: 'Crea immagini generate con AI.',
    image: '/people/immaginai.png',
    agentKey: 'immaginai',
  },
];

// Blocco “Giochi Interattivi”
const interactiveBlock = {
  name: 'Giochi Interattivi', 
  description: 'Crea quiz, esercizi e attività interattive.',
  image: '/people/giochi.png',
  agentKey: 'interactiveBlock',
};

// Sotto-agenti interattivi
const interactiveAgents = [
  {
    name: 'Quiz',
    description: 'Genera quiz stimolanti per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Completa frase',
    description: 'Esercizi dove gli studenti completano frasi con opzioni multiple.',
    image: '/people/fillthebox.png',
    agentKey: 'fillthebox',
  },
  {
    name: 'Raggruppa',
    description: 'Crea liste di parole suddivise in gruppi tematici.',
    image: '/people/selectgroup.png',
    agentKey: 'selectgroup',
  },
  {
    name: 'Riordina',
    description: 'Crea esercizi dove si devono riordinare le frasi.',
    image: '/people/rearrange.png',
    agentKey: 'rearrange',
  },
];

const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

// Animazione “pulse border” come in AgentStart
const pulseBorder = keyframes`
  0% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
  50% { box-shadow: 0 0 0 8px rgba(223,70,52,0.2); }
  100% { box-shadow: 0 0 0 4px rgba(223,70,52,0.5); }
`;

const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {boolean} tutorialFocusOnAttivita  Se true, oscura tutto tranne “Attività”
 */
const AgentStartMobile = ({ 
  open, 
  onClose, 
  handleNewChat,
  tutorialFocusOnAttivita = false, // <--- come in AgentStart
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showInteractiveSubmenu, setShowInteractiveSubmenu] = useState(false);

  useEffect(() => {
    if (open) {
      const imageUrls = [
        ...primaryAgents.map((a) => a.image),
        ...secondaryAgents.map((a) => a.image),
        interactiveBlock.image,
        ...interactiveAgents.map((a) => a.image),
      ];
      preloadImages(imageUrls)
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      setShowInteractiveSubmenu(false);
    }
  }, [open]);

  if (!open) return null;

  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          },
        }}
      >
        <LoadingIndicator />
      </Dialog>
    );
  }

  // Render di ogni “agente” in un Paper
  const renderAgentPaper = (agent) => {
    // Se tutorialFocusOnAttivita => disabilitiamo tutto tranne "attività"
    const isDisabled = tutorialFocusOnAttivita && agent.agentKey !== 'attività';

    return (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.5rem',
          borderRadius: '12px',
          backgroundColor: '#f9f9f9',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
          cursor: isDisabled ? 'default' : 'pointer',
          transition: 'background-color 0.3s, transform 0.3s',
          height: '130px',
          opacity: isDisabled ? 0.3 : 1,
          pointerEvents: isDisabled ? 'none' : 'auto',

          // Se è “attività” e tutorialFocus => evidenzia con animazione
          ...(tutorialFocusOnAttivita && agent.agentKey === 'attività'
            ? {
                boxShadow: '0 0 0 4px #DF4634',
                animation: `pulse-border 1.5s infinite`,
                '@keyframes pulse-border': {
                  '0%': { boxShadow: '0 0 0 4px rgba(223,70,52,0.5)' },
                  '50%': { boxShadow: '0 0 0 8px rgba(223,70,52,0.2)' },
                  '100%': { boxShadow: '0 0 0 4px rgba(223,70,52,0.5)' },
                },
              }
            : {}),

          '&:hover': {
            backgroundColor: isDisabled ? 'transparent' : '#f0f0f0',
            transform: isDisabled ? 'none' : 'scale(1.02)',
          },
          '&:active': {
            boxShadow: isDisabled
              ? 'none'
              : 'inset 0px 0px 5px rgba(0, 0, 0, 0.2)',
          },
        }}
        onClick={() => {
          if (!isDisabled) {
            handleNewChat(agent.agentKey);
            onClose();
          }
        }}
      >
        <Box
          component="img"
          src={agent.image}
          alt={agent.name}
          sx={{
            width: '40px',
            height: '40px',
            marginBottom: '0.5rem',
            borderRadius: '50%',
            backgroundColor: '#fff',
            padding: '0.2rem',
          }}
        />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 600,
            fontSize: '0.75rem',
            textAlign: 'center',
            color: '#333',
          }}
        >
          {agent.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.75rem',
            textAlign: 'center',
            color: '#666',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            marginTop: '0.25rem',
          }}
        >
          {agent.description}
        </Typography>
      </Paper>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: '#FFFFFF',
          padding: '1rem',
          width: { xs: '90%', sm: '80%', md: '60%' },
          maxHeight: '80vh',
          overflowY: 'auto',
          borderRadius: '16px',
          position: 'relative',
        },
      }}
    >
      {/* HEADER */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
          position: 'relative',
        }}
      >
        {!showInteractiveSubmenu && (
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, flexGrow: 1, textAlign: 'center' }}
          >
            Scegli l'esperto di cui hai bisogno
          </Typography>
        )}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/*
        Se tutorialFocusOnAttivita => testo animato
        “Clicca su ‘Attività’ per iniziare il tutorial operativo!”
        Esempio analogo alla versione desktop
      */}
      {tutorialFocusOnAttivita && !showInteractiveSubmenu && (
        <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
          <Typography
            sx={{
              color: '#DF4634',
              fontWeight: 'bold',
              marginBottom: '1rem',
              animation: 'bounceText 1.5s infinite',
              '@keyframes bounceText': {
                '0%, 100%': { transform: 'translateY(0)' },
                '50%': { transform: 'translateY(-5px)' },
              },
            }}
          >
            Clicca su “Attività” per iniziare il tutorial operativo!
          </Typography>
        </Box>
      )}

      {/* Se NON siamo nel submenu dei giochi */}
      {!showInteractiveSubmenu && (
        <>
          <Grid container spacing={1} justifyContent="center" sx={{ mb: 2 }}>
            {/* RIGA PRINCIPALE */}
            {primaryAgents.map((agent) => (
              <Grid item xs={4} sm={3} key={agent.agentKey}>
                {renderAgentPaper(agent)}
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={1} justifyContent="center">
            {/* RIGA SECONDARIA */}
            {secondaryAgents.map((agent) => (
              <Grid item xs={4} sm={3} key={agent.agentKey}>
                {renderAgentPaper(agent)}
              </Grid>
            ))}

            {/* Blocco GIOCHI => se tutorialFocus => oscurato */}
            <Grid item xs={4} sm={3} key={interactiveBlock.agentKey}>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.5rem',
                  borderRadius: '12px',
                  backgroundColor: '#f9f9f9',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s, transform 0.3s',
                  height: '130px',
                  // Se tutorialFocus => disabilitato
                  opacity: tutorialFocusOnAttivita ? 0.3 : 1,
                  pointerEvents: tutorialFocusOnAttivita ? 'none' : 'auto',

                  '&:hover': {
                    backgroundColor: tutorialFocusOnAttivita
                      ? 'transparent'
                      : '#f0f0f0',
                    transform: tutorialFocusOnAttivita
                      ? 'none'
                      : 'scale(1.02)',
                  },
                  '&:active': {
                    boxShadow: tutorialFocusOnAttivita
                      ? 'none'
                      : 'inset 0px 0px 5px rgba(0,0,0,0.2)',
                  },
                }}
                onClick={() => {
                  if (!tutorialFocusOnAttivita) {
                    setShowInteractiveSubmenu(true);
                  }
                }}
              >
                <Box
                  component="img"
                  src={interactiveBlock.image}
                  alt={interactiveBlock.name}
                  sx={{
                    width: '40px',
                    height: '40px',
                    marginBottom: '0.5rem',
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    padding: '0.2rem',
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    color: '#333',
                  }}
                >
                  {interactiveBlock.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    color: '#666',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    marginTop: '0.25rem',
                  }}
                >
                  {interactiveBlock.description}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {/* SUBMENU GIOCHI */}
      {showInteractiveSubmenu && (
        <>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, textAlign: 'center', mb: 2 }}
          >
            Scegli il tipo di gioco interattivo
          </Typography>
          <Grid container spacing={1} justifyContent="center" sx={{ mb: 2 }}>
            {interactiveAgents.map((agent) => (
              <Grid item xs={4} sm={3} key={agent.agentKey}>
                {renderAgentPaper(agent)}
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => setShowInteractiveSubmenu(false)}
              sx={{
                textTransform: 'none',
                borderColor: '#DF4634',
                color: '#DF4634',
                borderRadius: '1rem',
                '&:hover': {
                  backgroundColor: 'rgba(223,70,52,0.05)',
                },
              }}
            >
              Torna Indietro
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default AgentStartMobile;
